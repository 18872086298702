<template>
  <div class="main-layout">
    <br/>
    <router-view />
  </div>
</template>

<script>

export default {
  name: "Main",
  components: {},
  methods: {},
  mounted: function () {}
};

</script>

<style>
</style>